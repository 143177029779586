import React from 'react';

import defaultImageMeta from '../../../images/publication-cover.png';

import ImageMeta from './ImageMeta';

import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { resolve } from 'url';
import config from 'utils/siteConfig';

interface IWebsiteMetaSettings {
  allGhostSettings: {
    edges: {
      node: {
        logo: any;
        description: string;
        title: string;
        twitter: string;
        allGhostSettings: any;
        cover_image?: string;
      };
    }[];
  };
}

interface IWebsiteMetaSettingsProp {
  settings: IWebsiteMetaSettings;
}

interface IWebsiteMetaProps {
  data: {
    title?: string;
    meta_title?: string;
    meta_description?: string;
    name?: string;
    feature_image?: string;
    description?: string;
    bio?: string;
    profile_image?: string;
  };
  canonical: string;
  title?: string;
  description?: string;
  image?: string;
  type: string;
}

interface IWebsiteMetaFullProps extends IWebsiteMetaProps, IWebsiteMetaSettingsProp {}

const WebsiteMeta = ({
  data,
  settings: settingsData,
  canonical: canonicalOriginal,
  title,
  description,
  image,
  type,
}: IWebsiteMetaFullProps) => {
  const settings = settingsData.allGhostSettings.edges[0].node;

  const publisherLogo = resolve(config.siteUrl, settings.logo || config.siteIcon);
  let shareImage = image || data.feature_image || settings.cover_image || '';

  shareImage = shareImage ? resolve(config.siteUrl, shareImage) : defaultImageMeta;

  description =
    description ||
    data.meta_description ||
    data.description ||
    config.siteDescriptionMeta ||
    settings.description;
  title = `${title || data.meta_title || data.name || data.title} - ${settings.title}`;

  const canonical = canonicalOriginal.includes('/payday-loans-online-bad-credit/')
    ? `${config.siteUrl}/best-payday-loans-online/`
    : canonicalOriginal;

  const jsonLd = {
    '@context': 'https://schema.org/',
    '@type': type,
    url: canonical,
    image: shareImage
      ? {
          '@type': 'ImageObject',
          url: shareImage,
          width: config.shareImageWidth,
          height: config.shareImageHeight,
        }
      : undefined,
    publisher: {
      '@type': 'Organization',
      name: settings.title,
      logo: {
        '@type': 'ImageObject',
        url: publisherLogo,
        width: 60,
        height: 60,
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': config.siteUrl,
    },
    description,
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const WebsiteMetaQuery = (props: IWebsiteMetaProps) => {
  const renderWebsiteMeta = (data: IWebsiteMetaSettings) => (
    <WebsiteMeta settings={data} {...props} />
  );
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsWebsiteMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
                cover_image
              }
            }
          }
        }
      `}
      render={renderWebsiteMeta}
    />
  );
};

export default WebsiteMetaQuery;
