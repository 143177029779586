import React from 'react';

import {
  IAuthorMetaFullProps,
  IAuthorMetaProps,
  IAuthorSettings,
} from '../../../interfaces/Author';

import getAuthorProperties from './getAuthorProperties';
import ImageMeta from './ImageMeta';

import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import config from 'utils/siteConfig';

const AuthorMeta = ({ data, settings: settingsData, canonical }: IAuthorMetaFullProps) => {
  const settings = settingsData.allGhostSettings.edges[0].node;

  const author = getAuthorProperties(data);
  const shareImage = author.image || settings.cover_image || '';
  const title = `${data.name} - ${settings.title}`;
  const description = data.bio || config.siteDescriptionMeta || settings.description;

  const jsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Person',
    name: data.name,
    sameAs: author.sameAsArray ? author.sameAsArray : undefined,
    url: canonical,
    image: shareImage
      ? {
          '@type': 'ImageObject',
          url: shareImage,
          width: config.shareImageWidth,
          height: config.shareImageHeight,
        }
      : undefined,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': config.siteUrl,
    },
    description,
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical} />
        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="profile" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const AuthorMetaQuery = (props: IAuthorMetaProps) => {
  const renderAuthorMeta = (data: IAuthorSettings) => <AuthorMeta settings={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsAuthorMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
                cover_image
              }
            }
          }
        }
      `}
      render={renderAuthorMeta}
    />
  );
};

export default AuthorMetaQuery;
