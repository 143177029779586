import React from 'react';

import defaultImageMeta from '../../../images/publication-cover.png';
import {
  IArticleMetaGhostProps,
  IArticleMetaProps,
  IArticleSettings,
} from '../../../interfaces/Article';

import getAuthorProperties from './getAuthorProperties';
import ImageMeta from './ImageMeta';

import { tags as tagsHelper } from '@tryghost/helpers';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { resolve } from 'url';
import config from 'utils/siteConfig';

const ArticleMetaGhost = ({ data, settings: settingsData, canonical }: IArticleMetaGhostProps) => {
  const ghostPost = data;

  const {
    primary_author,
    feature_image,
    meta_title,
    title,
    published_at,
    updated_at,
    meta_description,
    excerpt,
    og_title,
    og_image,
    og_description,
    twitter_title,
    twitter_description,
  } = ghostPost;

  const settings = settingsData.allGhostSettings.edges[0].node;

  const author = getAuthorProperties(primary_author);
  const publicTags = tagsHelper(ghostPost, {
    visibility: 'public',
  })
    .split(',')
    .map((t: string) => t.trim());
  const primaryTag = publicTags[0] || '';
  const shareImage = og_image || feature_image || defaultImageMeta;
  const publisherLogo =
    settings.logo || config.siteIcon
      ? resolve(config.siteUrl, settings.logo || config.siteIcon)
      : undefined;

  const jsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    author: {
      '@type': 'Person',
      name: author.name,
      image: author.image ? author.image : undefined,
      sameAs: author.sameAsArray ? author.sameAsArray : undefined,
    },
    keywords: publicTags.length ? publicTags.join(', ') : undefined,
    headline: meta_title || title,
    url: canonical,
    datePublished: published_at,
    dateModified: updated_at,
    image: shareImage
      ? {
          '@type': 'ImageObject',
          url: shareImage,
          width: config.shareImageWidth,
          height: config.shareImageHeight,
        }
      : undefined,
    publisher: {
      '@type': 'Organization',
      name: settings.title,
      logo: {
        '@type': 'ImageObject',
        url: publisherLogo,
        width: 60,
        height: 60,
      },
    },
    description: meta_description || excerpt,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': config.siteUrl,
    },
  };

  return (
    <>
      <Helmet>
        <title>{meta_title || title}</title>
        <meta name="description" content={meta_description || excerpt} />
        <link rel="canonical" href={canonical} />

        <meta property="og:site_name" content={settings.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={og_title || meta_title || title} />
        <meta property="og:description" content={og_description || excerpt || meta_description} />
        <meta property="og:url" content={canonical} />
        <meta property="article:published_time" content={published_at} />
        <meta property="article:modified_time" content={updated_at} />
        {React.Children.toArray(
          /* tslint:disable-next-line jsx-key */
          publicTags.map((keyword: string) => <meta property="article:tag" content={keyword} />)
        )}
        {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />}

        <meta name="twitter:title" content={twitter_title || meta_title || title} />
        <meta
          name="twitter:description"
          content={twitter_description || excerpt || meta_description}
        />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={author.name} />
        {primaryTag && <meta name="twitter:label2" content="Filed under" />}
        {primaryTag && <meta name="twitter:data2" content={primaryTag} />}

        {settings.twitter && (
          <meta
            name="twitter:site"
            content={`https://twitter.com/${settings.twitter.replace(/^@/, '')}/`}
          />
        )}
        {settings.twitter && <meta name="twitter:creator" content={settings.twitter} />}
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </Helmet>
      <ImageMeta image={shareImage} />
    </>
  );
};

const ArticleMetaQuery = (props: IArticleMetaProps) => {
  const renderArticle = (data: IArticleSettings) => <ArticleMetaGhost settings={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsArticleMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={renderArticle}
    />
  );
};

export default ArticleMetaQuery;
