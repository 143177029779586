import React from 'react';

import ArticleMetaQuery from './ArticleMeta';
import AuthorMetaQuery from './AuthorMeta';
import WebsiteMetaQuery from './WebsiteMeta';

import { graphql, StaticQuery } from 'gatsby';
import { resolve } from 'url';
import config from 'utils/siteConfig';

interface IMetaData {
  ghostPost?: any;
  ghostTag?: any;
  ghostAuthor?: any;
  ghostPage?: any;
  allGhostAuthor?: any;
}

interface IMetaDataSettings {
  allGhostSettings: {
    edges: {
      node: {
        title: string;
        description: string;
        cover_image?: string;
      };
    }[];
  };
}

interface IMetaDataSettingsProp {
  settings: IMetaDataSettings;
}

interface IMetaDataProps {
  data: IMetaData;
  location: {
    pathname: string;
  };
  title?: string;
  description?: string;
  image?: string;
}

interface IMetaDataPropsFull extends IMetaDataProps, IMetaDataSettingsProp {}

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 *
 */
const MetaData = ({
  data,
  settings: settingsData,
  title,
  description,
  image,
  location,
}: IMetaDataPropsFull) => {
  const canonical = resolve(config.siteUrl, location.pathname);
  const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data;
  const settings = settingsData.allGhostSettings.edges[0].node;

  if (ghostPost) {
    return <ArticleMetaQuery data={ghostPost} canonical={canonical} />;
  } else if (ghostTag) {
    return <WebsiteMetaQuery data={ghostTag} canonical={canonical} type="Series" />;
  } else if (ghostAuthor) {
    return <AuthorMetaQuery data={ghostAuthor} canonical={canonical} />;
  } else if (ghostPage) {
    return <WebsiteMetaQuery data={ghostPage} canonical={canonical} type="WebSite" />;
  } else {
    title = title || config.siteTitleMeta || settings.title;
    description = description || config.siteDescriptionMeta || settings.description;
    image = image || settings.cover_image || '';

    image = image ? resolve(config.siteUrl, image) : '';

    return (
      <WebsiteMetaQuery
        data={{}}
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        type="WebSite"
      />
    );
  }
};

MetaData.defaultProps = {
  data: {},
};

const MetaDataQuery = (props: IMetaDataProps) => {
  const renderMetaData = (data: IMetaDataSettings) => <MetaData settings={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsMetaData {
          allGhostSettings {
            edges {
              node {
                title
                description
                cover_image
              }
            }
          }
        }
      `}
      render={renderMetaData}
    />
  );
};

export default MetaDataQuery;
